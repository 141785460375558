//* #region ----------------------------------------------- Component Imports

import makeAudioPlayer from "@WebComponents/AudioPlayer/audio-player.js"
import makeSearchResults from "@WebComponents/SearchResults/search-results.js"
import makeFilterForm from "@WebComponents/SideBar/FilterForm/filter-form.js"
import makeSearchForm from "@WebComponents/SideBar/SearchForm/search-form.js"
import APP_EVENTS from "../init/events.js"
import makeAppResizer from "./app-resizer.js"

//* #endregion ----------------------------------------------- Component Imports

export default function makeAppComponents() {
	//* ---------- Component Factories -----------------------------------------------

	const appResizer = makeAppResizer()
	const filterForm = makeFilterForm()
	const searchForm = makeSearchForm({ events: APP_EVENTS })
	const searchResults = makeSearchResults()
	const audioPlayer = makeAudioPlayer()

	//* ---------- Exposed Factories -----------------------------------------------

	return Object.freeze({
		appResizer,
		filterForm,
		searchForm,
		searchResults,
		audioPlayer
	})
}
