//* #region ----------------------------------------------- Types

import { getMiscTitles } from "@RenderLiterals/lang-titles.js"
import type { T_LangToggle } from "@WebComponents/LangToggle/LangToggle.js"
import type { MyComponentTypes } from "haq"

type T = MU_SideBar & {
	langToggleEvents: T_LangToggle["events"]

	events: {
		"hamburger-clicked": undefined
	}

	globalEvents: {
		"subscribe-btn-clicked": undefined
	}
}
type X<D> = MyComponentTypes<D>
type C = T["SideBar"]
type E = T["events"]

export type T_SideBar = T

//* #endregion ----------------------------------------------- Types

//* #region -----------------------------------------------  Module Imports (only utility/helper modules)

import { $, TypedComponent, defineComponent, makeAppEvents, makeComponentEvent, makeComponentEventListener } from "haq"

//* #endregion -----------------------------------------------  Module Imports (only utility/helper modules)

export class SideBar extends HTMLElement {
	private Component: ReturnType<typeof TypedComponent<C>>
	private MastHead: X<T["MastHead"]>["MastHead"]
	private EnLink: X<T["MastHead"]>["EnLink"]
	private ArLink: X<T["MastHead"]>["ArLink"]
	private Hamburger: X<T["MastHead"]>["Hamburger"]
	private MyNav: X<T["MyNav"]>["MyNav"]
	private NavLinks: X<T["MyNav"]>["EnglishNav"][]
	private LangToggle: X<T>["LangToggle"]
	private SubscribeBtn: X<T>["SubscribeBtn"]
	private Footer: X<T["Footer"]>["Footer"]

	private INITIALIZED = false //handle Astro persisted components using View transitions

	private hamburgerClickEvent = makeComponentEvent<E>({
		triggerEl: this,
		eventName: "hamburger-clicked",
		bubbles: true
	})

	constructor() {
		super()

		this.Component = TypedComponent<C>(this)
		this.MastHead = $<T["MastHead"]["MastHead"]>(this, "mast-head")[0]
		this.EnLink = $<T["MastHead"]["EnLink"]>(this.MastHead, "[x_selector='enLink']")[0]
		this.ArLink = $<T["MastHead"]["ArLink"]>(this.MastHead, "[x_selector='arLink']")[0]
		this.Hamburger = $<T["MastHead"]["Hamburger"]>(this.MastHead, ".hamburger")[0]
		this.MyNav = $<T["MyNav"]["MyNav"]>(this, "my-nav")[0]
		this.NavLinks = $<T["MyNav"]["EnglishNav"]>(this.MyNav, "a")
		this.LangToggle = $<T["LangToggle"]>(this, "lang-toggle")[0]
		this.SubscribeBtn = $<T["SubscribeBtn"]>(this, "[x_selector='subscribeBtn']")[0]
		this.Footer = $<T["Footer"]["Footer"]>(this, "footer")[0]
	}

	//* ---------- Initialize -----------------------------------------------

	connectedCallback() {
		if (this.INITIALIZED) return

		this.Hamburger.addEventListener("click", () => this._onNavToggle())
		this.EnLink.addEventListener("click", this._onHomeLangClick.bind(this, "ar"))
		this.ArLink.addEventListener("click", this._onHomeLangClick.bind(this, "en"))

		const langToggleListeners = makeComponentEventListener<T["langToggleEvents"]>(this.Component)
		langToggleListeners.add("lang-switched", (e) => this._onLangSwitch(e.detail.lang))

		this.SubscribeBtn.addEventListener("click", this._onSubscribeBtnClick.bind(this))

		this.INITIALIZED = true
	}

	//* ---------- Exposed Methods -----------------------------------------------

	initialize() {
		this.Component.classList.remove("hidden")
	}

	get isOpen() {
		return this.Component.getAttribute("d_selected") !== null
	}

	closeNav() {
		this.Component.removeAttribute("d_selected")
		this.Hamburger.removeAttribute("d_selected")
		this.scrollTop = 0
		this.hamburgerClickEvent.emit()
	}

	setDimensions(sideBarWidth: string, sideBarHeight: string) {
		this.Component.style.setProperty("--_width", sideBarWidth)
		this.Component.style.setProperty("--_height", sideBarHeight)
	}

	setActiveNavLink(page: NavPage) {
		for (const Link of this.NavLinks) {
			Link.removeAttribute("d_selected")
		}
		const ActiveLinks = this.NavLinks.filter((Link) => Link.getAttribute("d_page") === page)
		for (const Link of ActiveLinks) {
			Link.setAttribute("d_selected", true)
		}
	}

	//* ---------- Listeners -----------------------------------------------

	private _onHomeLangClick(lang: Lang) {
		const currentLang = this.LangToggle.currentLang
		this.LangToggle.triggerLangChangeEvent(currentLang === lang)
	}

	private _onNavToggle() {
		this.Component.toggleAttribute("d_selected")
		this.Hamburger.toggleAttribute("d_selected")
		this.scrollTop = 0
		this.hamburgerClickEvent.emit()
	}

	private _onLangSwitch(lang: Lang) {
		if (lang === "ar") {
			this.Component.setAttribute("d_arabic", true)
		} else {
			this.Component.removeAttribute("d_arabic")
		}
		this._handleMastHeadLang(lang)
		this._handleNavLinksLang(lang)
		this._handleSubscribeBtnLang(lang)
		this._handleFooterLang(lang)
	}

	private _onSubscribeBtnClick() {
		const globalEvents = makeAppEvents<T["globalEvents"]>()
		globalEvents.emit({
			action: "subscribe-btn-clicked",
			data: {} as never
		})
	}

	//* ---------- Lang Switch -----------------------------------------------

	private _handleMastHeadLang(lang: Lang) {
		const EnLink = $<T["MastHead"]["EnLink"]>(this.MastHead, "[x_selector='enLink']")[0]
		const ArLink = $<T["MastHead"]["ArLink"]>(this.MastHead, "[x_selector='arLink']")[0]
		if (lang === "ar") {
			ArLink.setAttribute("d_selected", true)
			EnLink.removeAttribute("d_selected")
			return
		}
		EnLink.setAttribute("d_selected", true)
		ArLink.removeAttribute("d_selected")
	}

	private _handleNavLinksLang(lang: Lang) {
		const enContent = this.NavLinks.filter((Link) => Link.getAttribute("d_lang") === "en")
		const arContent = this.NavLinks.filter((Link) => Link.getAttribute("d_lang") === "ar")
		for (const el of enContent) {
			if (lang === "en") el.classList.remove("no-display")
			else el.classList.add("no-display")
		}
		for (const el of arContent) {
			if (lang === "ar") el.classList.remove("no-display")
			else el.classList.add("no-display")
		}
	}

	private _handleSubscribeBtnLang(lang: Lang) {
		this.SubscribeBtn.setAttribute("d_lang", lang)
		this.SubscribeBtn.text = getMiscTitles("subscribe")[lang]
	}

	private _handleFooterLang(lang: Lang) {
		const enContent = $<T["Footer"]["MultiLangElement"]>(this.Footer, "[d_lang='en']")
		const arContent = $<T["Footer"]["MultiLangElement"]>(this.Footer, "[d_lang='ar']")
		for (const el of enContent) {
			if (lang === "en") el.classList.remove("no-display")
			else el.classList.add("no-display")
		}
		for (const el of arContent) {
			if (lang === "ar") el.classList.remove("no-display")
			else el.classList.add("no-display")
		}
	}
}

export default function SideBarInit() {
	defineComponent<C>("side-bar", SideBar)
}
