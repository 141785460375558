//* #region ----------------------------------------------- Types

import type { T_LazyImage } from "@WebComponents/LazyImage/LazyImage.js"
import type { MyComponentTypes } from "haq"

type T = MU_ImagePreviewModal & {
	imageEvents: T_LazyImage["events"]
}
type X = MyComponentTypes<T>
type C = T["ImagePreviewModal"]

export type T_ImagePreviewModal = T

//* #endregion ----------------------------------------------- Types

//* #region -----------------------------------------------  Module Imports (only utility/helper modules)

import { $, _, defineComponent, makeComponentEventListener } from "haq"

//* #endregion -----------------------------------------------  Module Imports (only utility/helper modules)

export class ImagePreviewModal extends HTMLElement {
	private Overlay: X["ImagePreviewOverlay"]
	private MyCarousel: X["MyCarousel"]

	private INITIALIZED = false //handle Astro persisted components using View transitions

	constructor() {
		super()

		this.Overlay = _<T["ImagePreviewOverlay"]>("imagePreviewOverlay")
		this.MyCarousel = $<T["MyCarousel"]>(this, "my-carousel")[0]
	}

	//* ---------- Initialize -----------------------------------------------

	connectedCallback() {
		if (this.INITIALIZED) return

		const Main = $<MU_Main["Main"]>(document, "main")[0]
		const imageEvents = makeComponentEventListener<T["imageEvents"]>(Main)
		imageEvents.add("previewable-clicked", (e) => this._showImagePreview(e.detail.index))

		this.INITIALIZED = true
	}

	//* ---------- Listeners -----------------------------------------------

	private _showImagePreview(index: number) {
		this.Overlay.addEscapeListener()
		this.Overlay.show()
		this.MyCarousel.showAtIndex(index)
	}
}

export default function ImagePreviewModalInit() {
	defineComponent<C>("image-preview-modal", ImagePreviewModal)
}
