//* #region ----------------------------------------------- Types

import type { T_LangToggle } from "@WebComponents/LangToggle/LangToggle"
import type { T_MultiLangInput } from "@WebComponents/MultiLangInput/MultiLangInput"
import type { T_MyTag } from "@WebComponents/MyTag/MyTag"
import type { T_RemoveFilterBtn } from "@WebComponents/RemoveFilterBtn/RemoveFilterBtn"

type T = {
	FilterForm: MU_FilterForm
	FilterResults: MU_FilterResults["FilterResults"]
	TapeGrid: MU_TapeGrid["TapeGrid"]
	langToggleEvents: T_LangToggle["events"]
	multiLangInputEvents: T_MultiLangInput["events"]
	removeFilterBtnEvents: T_RemoveFilterBtn["events"]
	myTagEvents: T_MyTag["events"]
}

//* #endregion ----------------------------------------------- Types

//* #region ----------------------------------------------- Module Imports (only utility/helper modules)

import { navigate } from "astro:transitions/client"
import RootDoc from "@helpers/rootDoc"
import { $, TypedFormData, TypedURLSearchParams, _, makeComponentEventListener } from "haq"
import { entriesFromObject } from "src/server/utils/generic-helpers"

//* #endregion ----------------------------------------------- Module Imports (only utility/helper modules)

export default function makeFilterForm() {
	const FilterForm = _<T["FilterForm"]["FilterForm"]>("filterForm")
	const HiddenLangInput = $<T["FilterForm"]["HiddenInput"]>(FilterForm, "[x_selector='HiddenInput']")[0]
	const MultiLangInputs = $<T["FilterForm"]["MultiLangInput"]>(FilterForm, "multi-lang-input")

	const FilterResults = $<T["FilterResults"]>(document, "filter-results")[0]

	let initialized = false

	return Object.freeze({
		init,
		update
	})

	function init() {
		if (initialized) return

		const langToggleListeners = makeComponentEventListener<T["langToggleEvents"]>(RootDoc)
		langToggleListeners.add("lang-switched", (e) => _onLangSwitch(e.detail.lang))

		const multiLangInputListeners = makeComponentEventListener<T["multiLangInputEvents"]>(FilterForm)
		multiLangInputListeners.add("select-input-changed", () => _onFilterFormSubmit())

		const removeFilterBtnListeners = makeComponentEventListener<T["removeFilterBtnEvents"]>(RootDoc)
		removeFilterBtnListeners.add("remove-filter-btn-clicked", (e) => _onRemoveFilterBtnClick(e.detail))

		const myTagListeners = makeComponentEventListener<T["myTagEvents"]>(RootDoc)
		myTagListeners.add("my-tag-clicked", (e) => _onTagClick(e.detail))

		update()
		initialized = true
	}

	function update() {
		const currentPage = RootDoc.getAttribute("d_page")
		if (!currentPage) return

		for (const Input of MultiLangInputs) {
			Input.resetValues()
		}

		const queryString = window.location.search
		const URLParams = TypedURLSearchParams<T["FilterForm"]["FormData"]>(queryString)
		const currentLang: Lang = URLParams.get("lang") || "en"

		const filterStateObj = <{ [K in keyof TapeFilters]: string[] }>{}
		let hasFilters = false

		for (const [key, value] of URLParams.entries()) {
			if (key === "lang" || !value) continue

			hasFilters = true

			if (filterStateObj[key]) {
				filterStateObj[key].push(value)
				continue
			}
			filterStateObj[key] = [value]
		}

		if (!hasFilters || currentPage !== "tapes") {
			FilterResults.hide()
			setTimeout(() => FilterResults.reset(), 600)
			return
		}

		FilterResults.reset()
		FilterResults.show()

		for (const [key, value] of entriesFromObject(filterStateObj)) {
			if (!value) continue
			FilterResults.update(value, currentLang, key)
			const TargetInput = MultiLangInputs.find((Input) => Input.inputName === key)
			if (!TargetInput) continue
			TargetInput.setValues(value)
		}

		const TapeGrid = $<T["TapeGrid"]>(document, "tape-grid")[0]
		if (TapeGrid.numOfItems() > 1) FilterResults.hideNoResults()
		else FilterResults.showNoResults(currentLang)
	}

	function _onLangSwitch(lang: Lang) {
		HiddenLangInput.value = lang
		for (const Input of MultiLangInputs) {
			Input.setLang(lang)
		}
	}

	function _onFilterFormSubmit() {
		const FormData = TypedFormData<T["FilterForm"]["FormData"]>(FilterForm)
		const URLParams = TypedURLSearchParams<T["FilterForm"]["FormData"]>()
		for (const [key, value] of FormData.entries()) {
			URLParams.append(key, value)
		}
		const newURL = `/?${URLParams.toString()}`

		navigate(newURL)
	}

	function _onRemoveFilterBtnClick(detail: T["removeFilterBtnEvents"]["remove-filter-btn-clicked"]) {
		const TargetInput = MultiLangInputs.find((Input) => Input.inputName === detail.name)
		if (!TargetInput) return
		TargetInput.resetValues()
		TargetInput.triggerInputChange(detail.lang)
	}

	function _onTagClick(detail: T["myTagEvents"]["my-tag-clicked"]) {
		const TargetInput = MultiLangInputs.find((Input) => Input.inputName === detail.name)
		if (!TargetInput) return
		TargetInput.setValue(detail.lang, detail.value)
		TargetInput.triggerInputChange(detail.lang)
	}
}
