//* #region ----------------------------------------------- Types

import type { MyComponentTypes } from "haq"

type T = MU_TapeGrid
type X = MyComponentTypes<T>
type C = T["TapeGrid"]

export type T_TapeGrid = T

//* #endregion ----------------------------------------------- Types

//* #region -----------------------------------------------  Module Imports (only utility/helper modules)

import { $, TypedComponent, defineComponent } from "haq"

//* #endregion -----------------------------------------------  Module Imports (only utility/helper modules)

export class TapeGrid extends HTMLElement {
	private Component: ReturnType<typeof TypedComponent<C>>
	private EmptyItem: X["Item"]

	constructor() {
		super()

		this.Component = TypedComponent<C>(this)
		this.EmptyItem = $<T["Item"]>(this, ".item")[0]
	}

	//* ---------- Exposed methods -----------------------------------------------

	get gridItemDimensions() {
		return this.EmptyItem.getBoundingClientRect()
	}

	set paddingInlineStart(value: string) {
		this.Component.style.setProperty("--_paddingInlineStart", value)
	}

	showEmptyItem() {
		this.EmptyItem.classList.remove("no-display")
	}

	showGrid() {
		this.Component.classList.remove("no-display")
	}

	hideEmptyItem() {
		this.EmptyItem.classList.add("no-display")
	}

	hideGrid() {
		this.Component.classList.add("no-display")
	}

	numOfItems() {
		return $<T["Item"]>(this.Component, ".item").length
	}
}

export default function TapeGridInit() {
	defineComponent<C>("tape-grid", TapeGrid)
}
