//* #region ----------------------------------------------- Types

import type { MyComponentTypes } from "haq"

type T = MU_MyCarousel

type X = MyComponentTypes<T>
type C = T["MyCarousel"]

export type T_MyCarousel = T

//* #endregion ----------------------------------------------- Types

//* #region -----------------------------------------------  Module Imports (only utility/helper modules)

import { $, TypedComponent, defineComponent } from "haq"

//* #endregion -----------------------------------------------  Module Imports (only utility/helper modules)

export class MyCarousel extends HTMLElement {
	private Component: ReturnType<typeof TypedComponent<C>>
	private PrevButton: X["LeftBtn"]
	private NextButton: X["RightBtn"]
	private Carousel: X["Carousel"]
	private Images: X["LazyImage"][]
	private CarouselCounter: X["CarouselCounter"]
	private Caption: X["Caption"]
	private PhotoBy: X["PhotoBy"]

	private CURRENT_LANG: Lang | null = null

	private CURRENT_INDEX = 0

	private IS_TOUCH_DEVICE = "ontouchstart" in document.documentElement
	private START_X = 0
	private PREVENT_SCROLL_EVENT = false

	private INITIALIZED = false // use for persited components using transition:persist to avoid multiple initalization

	constructor() {
		super()

		this.Component = TypedComponent<C>(this)
		this.PrevButton = $<T["LeftBtn"]>(this, ".left-btn")[0]
		this.NextButton = $<T["RightBtn"]>(this, ".right-btn")[0]
		this.Carousel = $<T["Carousel"]>(this, ".carousel")[0]
		this.Images = $<T["LazyImage"]>(this, "lazy-image")
		this.CarouselCounter = $<T["CarouselCounter"]>(this, ".carousel-counter")[0]
		this.Caption = $<T["Caption"]>(this, "[x_selector='caption']")[0]
		this.PhotoBy = $<T["PhotoBy"]>(this, "[x_selector='photoBy']")[0]
	}

	//* ---------- Initialize -----------------------------------------------

	connectedCallback() {
		if (this.INITIALIZED) return

		this.CURRENT_LANG = this.Component.getAttribute("d_lang") || "en"

		//remove nav buttons if only 1 image
		if (this.Images.length < 2) {
			this.PrevButton.classList.add("no-display")
			this.NextButton.classList.add("no-display")
			return
		}

		this.PrevButton.addEventListener("click", this._onPreviousClick.bind(this))

		this.NextButton.addEventListener("click", this._onNextClick.bind(this))

		if (this.IS_TOUCH_DEVICE) {
			this.Carousel.addEventListener("touchstart", this._onTouchStart.bind(this))
			this.Carousel.addEventListener("touchend", this._onTouchEnd.bind(this))
		} else {
			this.Carousel.addEventListener("scroll", this._onScroll.bind(this))
		}

		this._updateButtons()
		this._updateCounter()
		this._updateCaption()

		this.INITIALIZED = true
	}

	//* ---------- Exposed Methods -----------------------------------------------

	showAtIndex(index: number) {
		//check if index is in bounds
		if (index < 0 || index >= this.Images.length) return
		this._scrollToIndex(index, "instant")
	}

	//* ---------- Listeners -----------------------------------------------

	private _onPreviousClick() {
		if (this.CURRENT_INDEX > 0) {
			this._scrollToIndex(this.CURRENT_INDEX - 1, "smooth")
		}
	}

	private _onNextClick() {
		if (this.CURRENT_INDEX < this.Images.length - 1) {
			this._scrollToIndex(this.CURRENT_INDEX + 1, "smooth")
		}
	}

	private _onScroll() {
		if (this.PREVENT_SCROLL_EVENT) return

		const itemWidth = this.Images[this.CURRENT_INDEX].offsetWidth
		const newIndex = Math.round(this.Carousel.scrollLeft / itemWidth)
		if (newIndex !== this.CURRENT_INDEX) {
			this.CURRENT_INDEX = newIndex
			this._updateButtons()
			this._updateCounter()
			this._updateCaption()
		}
	}

	private _onTouchStart(e: TouchEvent) {
		this.START_X = e.touches[0].clientX
	}

	private _onTouchEnd(e: TouchEvent) {
		const endX = e.changedTouches[0].clientX
		const distance = endX - this.START_X
		const SWIPE_DISTANCE_THRESHOLD = 50 // Minimum swipe distance

		if (Math.abs(distance) > SWIPE_DISTANCE_THRESHOLD) {
			if (distance < 0 && this.CURRENT_INDEX < this.Images.length - 1) {
				this._scrollToIndex(this.CURRENT_INDEX + 1, "smooth")
			} else if (distance > 0 && this.CURRENT_INDEX > 0) {
				this._scrollToIndex(this.CURRENT_INDEX - 1, "smooth")
			}
		}
	}

	//* ---------- DOM Updates -----------------------------------------------

	private _updateCounter() {
		this.CarouselCounter.innerText = `${this.CURRENT_INDEX + 1} / ${this.Images.length}`
	}

	private _updateButtons() {
		this.PrevButton.disabled = this.CURRENT_INDEX === 0
		this.NextButton.disabled = this.CURRENT_INDEX === this.Images.length - 1
	}

	private _updateCaption() {
		this.Caption.innerText = ""
		this.PhotoBy.innerText = ""

		const caption = this.Images[this.CURRENT_INDEX].getAttribute("d_caption")
		const photoBy = this.Images[this.CURRENT_INDEX].getAttribute("d_photoBy")
		if (caption) {
			this.Caption.innerText = caption
		}
		if (photoBy) {
			const creditPrefix = this.CURRENT_LANG === "ar" ? "صورة لـ" : "Photo by"
			this.PhotoBy.innerText = `${creditPrefix} ${photoBy}`
		}
	}

	private _scrollToIndex(index: number, behavior: ScrollOptions["behavior"]) {
		this.PREVENT_SCROLL_EVENT = true
		const itemWidth = this.Images[index].offsetWidth
		this.Carousel.scrollTo({
			left: itemWidth * index,
			behavior
		})
		this.CURRENT_INDEX = index
		this._updateButtons()
		this._updateCounter()
		this._updateCaption()
		setTimeout(() => {
			this.PREVENT_SCROLL_EVENT = false
		}, 500)
	}
}

export default function MyCarouselInit() {
	defineComponent<C>("my-carousel", MyCarousel)
}
