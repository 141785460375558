export function getPropTitles(prop: keyof TapeView) {
	let title = { en: "", ar: "" }
	switch (prop) {
		case "ref":
			title = { en: "Ref No:", ar: "رقم المرجع:" }
			break
		/* case "collection":
			title = { en: "Collection:", ar: "رقم المرجع:" };
			break; */
		case "genre":
			title = { en: "Genres:", ar: "جنرات:" }
			break
		case "decade":
			title = { en: "Decade:", ar: "تاريخ الإنتاج:" }
			break
		case "year":
			title = { en: "Date:", ar: "تاريخ التسجيل:" }
			break
		case "locationIssued":
			title = { en: "Location Issued", ar: "مكان الإصدار" }
			break
		case "artistOrigin":
			title = { en: "Artist Origin", ar: "أصل الفنان" }
			break
		case "label":
			title = { en: "Label", ar: "المنتج" }
			break
		case "creditsEN":
			title = { en: "Credits", ar: "أسماء مشاركة" }
			break
		case "commentsEN":
			title = { en: "More Info", ar: "معلومات إضافية" }
			break
		case "lyricsEN":
			title = { en: "Lyrics", ar: "كلمات الأغاني" }
			break
	}
	return title
}

export type MiscTitles =
	| "no-results"
	| "search-results-header"
	| "about"
	| "team"
	| "dev-credits"
	| "collaborators"
	| "previous-collaborators"
	| "partners"
	| "previous-funding"
	| "special-thanks"
	| "contributions"
	| "disclaimer"
	| "contact"
	| "copyright"
	| "your-name"
	| "message"
	| "send"
	| "donate"
	| "subscribe"
	| "signup"

export function getMiscTitles(prop: MiscTitles) {
	let title = { en: "", ar: "" }
	switch (prop) {
		case "no-results":
			title = { en: "No results", ar: "لا يوجد نتائج" }
			break
		case "search-results-header":
			title = { en: "Search results", ar: "نتائج البحث" }
			break
		case "about":
			title = { en: "About", ar: "حول المشروع" }
			break
		case "team":
			title = { en: "Syrian Cassette Archives", ar: "أرشيف الشرائط السورية" }
			break
		case "dev-credits":
			title = {
				en: "Design & Development",
				ar: "تصميم وتطوير الموقع"
			}
			break
		case "collaborators":
			title = {
				en: "Collaborators",
				ar: "المشاركون الحاليون"
			}
			break
		case "previous-collaborators":
			title = {
				en: "Previous Collaborators",
				ar: "المشاركون السابقون"
			}
			break
		case "partners":
			title = {
				en: "Production Partners, 2020-2022",
				ar: "شركاء الإنتاج, 2020-2022"
			}
			break
		case "previous-funding":
			title = { en: "Previous Funding", ar: "التمويل السابق" }
			break
		case "special-thanks":
			title = { en: "Special Thanks", ar: "شكر خاص" }
			break
		case "contributions":
			title = { en: "Cassette Contributions", ar: "مساهمات الشرائط" }
			break
		case "disclaimer":
			title = { en: "Disclaimer", ar: "إخلاء مسؤولية" }
			break
		case "contact":
			title = { en: "Contact", ar: "اتصل بنا" }
			break
		case "copyright":
			title = { en: "Copyright", ar: "حقوق الملكية" }
			break
		case "your-name":
			title = { en: "Your Name", ar: "اسمك" }
			break
		case "message":
			title = { en: "Message...", ar: "رسالتك..." }
			break
		case "send":
			title = { en: "Send", ar: "إرسال" }
			break
		case "donate":
			title = { en: "Donate", ar: "ادعم المشروع" }
			break
		case "subscribe":
			title = { en: "Subscribe", ar: "الإشتراك" }
			break
		case "signup":
			title = { en: "Sign Up", ar: "إنشاء حساب" }
			break
	}
	return title
}
