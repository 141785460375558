//* #region ----------------------------------------------- Types

import type { T_SideBar } from "@WebComponents/SideBar/SideBar.js"
import type { MyComponentTypes } from "haq"

type T = {
	MainContainer: MU_MainContainer["MainContainer"]
	TapeGrid: MU_TapeGrid["TapeGrid"]
	SideBar: MU_SideBar
	sideBarEvents: T_SideBar["events"]
	AudioPlayer: MU_AudioPlayer["AudioPlayer"]
}
type X = MyComponentTypes<T>

//* #endregion ----------------------------------------------- Types

//* #region ----------------------------------------------- Module Imports (only utility/helper modules)

import RootDoc from "@helpers/rootDoc"
import { $, getTypedComputedStyle, makeComponentEventListener } from "haq"

//* #endregion ----------------------------------------------- Module Imports (only utility/helper modules)

export default function makeAppResizer() {
	let initialized = false

	//ONLY PERSITED ELEMENTS AND CONSTANTS
	const SideBar = $<T["SideBar"]["SideBar"]>(document, "side-bar")[0]
	const LangToggle = $<T["SideBar"]["LangToggle"]>(SideBar, "lang-toggle")[0]
	const AudioPlayer = $<T["AudioPlayer"]>(document, "audio-player")[0]

	const RootDocStyle = getTypedComputedStyle<MU_RootDocument["RootDocument"]>(RootDoc)
	const PADDING = Number.parseInt(RootDocStyle.getPropertyValue("--_gridPadding"))

	const TABLET_WIDTH = 1024

	const IS_DESKTOP_SIZE = () => window.innerWidth > TABLET_WIDTH
	const IS_TABLET_SIZE = () => window.innerWidth <= TABLET_WIDTH

	return Object.freeze({
		init,
		afterViewTransition
	})

	function init() {
		//EXECUTED ONCE IN APP'S LIFE CYCLE
		if (initialized || Number.isNaN(PADDING)) return

		window.addEventListener("resize", _onResize)
		_onResize()
		SideBar.initialize()

		const sideBarListeners = makeComponentEventListener<T["sideBarEvents"]>(RootDoc)
		sideBarListeners.add("hamburger-clicked", _onNavToggle)

		initialized = true
	}

	function afterViewTransition() {
		_onResize()
		LangToggle.updateLangURL()
		const currentPage = RootDoc.getAttribute("d_page")
		if (!currentPage) return
		SideBar.setActiveNavLink(currentPage)
		if (IS_TABLET_SIZE()) SideBar.closeNav()
	}

	function _onResize() {
		const TapeGrid = $<T["TapeGrid"]>(document, "tape-grid")[0]
		if (!TapeGrid) return

		const dimensions = _getGridItemDimensions(TapeGrid)
		_handleSideBar(dimensions, TapeGrid)
		_handleEmptyGridItem(TapeGrid)
		_handleAudioPlayer(dimensions)
		if (IS_TABLET_SIZE()) SideBar.closeNav()

		if (window.location.pathname !== "/") TapeGrid.hideGrid()
	}

	function _getGridItemDimensions(TapeGrid: X["TapeGrid"]) {
		TapeGrid.showGrid()
		TapeGrid.showEmptyItem() //in order to get correct boundingClientRect values
		return TapeGrid.gridItemDimensions
	}

	function _handleEmptyGridItem(TapeGrid: X["TapeGrid"]) {
		if (IS_TABLET_SIZE() || (IS_DESKTOP_SIZE() && SideBar.isOpen)) {
			TapeGrid.hideEmptyItem()
		} else if (IS_DESKTOP_SIZE() && !SideBar.isOpen) {
			TapeGrid.showEmptyItem()
		}
	}

	function _handleSideBar(dimensions: DOMRect, TapeGrid: X["TapeGrid"]) {
		const sideBarWidth = IS_DESKTOP_SIZE() ? `${dimensions.width + PADDING}px` : "100%"
		const sideBarHeight = IS_DESKTOP_SIZE() ? `${dimensions.height + PADDING * 2}px ` : "100px"
		SideBar.setDimensions(sideBarWidth, sideBarHeight)
		if (SideBar.isOpen) {
			TapeGrid.paddingInlineStart = IS_DESKTOP_SIZE() ? `${dimensions.width + PADDING}px` : "0px"
		} else {
			TapeGrid.paddingInlineStart = "0px"
		}

		const MainContainer = $<T["MainContainer"]>(document, "main-container")[0]
		if (!MainContainer) return
		MainContainer.style.setProperty(
			"--_paddingInlineStart",
			IS_DESKTOP_SIZE() ? `${dimensions.width + PADDING}px` : "0px"
		)
	}

	function _onNavToggle() {
		const TapeGrid = $<T["TapeGrid"]>(document, "tape-grid")[0]
		const dimensions = _getGridItemDimensions(TapeGrid)

		_handleSideBar(dimensions, TapeGrid)
		_handleAudioPlayer(dimensions)

		if (IS_DESKTOP_SIZE()) {
			if (SideBar.isOpen) TapeGrid.hideEmptyItem()
			else TapeGrid.showEmptyItem()
		} else if (IS_TABLET_SIZE()) {
			if (SideBar.isOpen) RootDoc.classList.add("no-scroll")
			else RootDoc.classList.remove("no-scroll")
			TapeGrid.hideEmptyItem()
		}

		if (window.location.pathname !== "/") TapeGrid.hideGrid()
	}

	function _handleAudioPlayer(dimensions: DOMRect) {
		if (SideBar.isOpen) {
			const width = IS_DESKTOP_SIZE() ? `calc(100% - ${dimensions.width + PADDING}px)` : "100%"
			const marginInlineStart = IS_DESKTOP_SIZE() ? `${dimensions.width + PADDING}px` : "0"
			AudioPlayer.style.setProperty("--_width", width)
			AudioPlayer.style.setProperty("--_marginInlineStart", marginInlineStart)
		} else {
			AudioPlayer.style.setProperty("--_width", "100%")
			AudioPlayer.style.setProperty("--_marginInlineStart", "0")
		}
	}
}
