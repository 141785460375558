//* #region ----------------------------------------------- Types

type T = MU_MyTag & {
	events: {
		"my-tag-clicked": {
			lang: Lang
			name: Attributes["d_name"]
			value: string
		}
	}
}
type C = T["MyTag"]
type E = T["events"]
type Attributes = C["attr"]

export type T_MyTag = T

//* #endregion ----------------------------------------------- Types

//* #region -----------------------------------------------  Module Imports (only utility/helper modules)

import { TypedComponent, defineComponent, makeComponentEvent } from "haq"

//* #endregion -----------------------------------------------  Module Imports (only utility/helper modules)

export class MyTag extends HTMLElement {
	private Component: ReturnType<typeof TypedComponent<C>>

	private INITIALIZED = false

	constructor() {
		super()

		this.Component = TypedComponent<C>(this)
	}

	connectedCallback() {
		if (this.INITIALIZED) return

		this.Component.addEventListener("click", this._onTagClick.bind(this))
		this.INITIALIZED = true
	}

	private _onTagClick() {
		const name = this.Component.getAttribute("d_name")
		const lang = this.Component.getAttribute("d_lang")
		if (!name || !lang) return

		const clickEvent = makeComponentEvent<E>({
			triggerEl: this.Component,
			eventName: "my-tag-clicked",
			detail: { lang, name, value: this.Component.innerText },
			bubbles: true
		})

		clickEvent.emit()
	}
}

export default function MyTagInit() {
	defineComponent<C>("my-tag", MyTag)
}
