/* RUNTIME TYPE CHECKING HELPERS
-----------------------------------------------*/

export function hasPostResponseData<T extends keyof POSTRoutes>(
	response: POSTResponse<T>
): response is POSTResponse<T> & {
	postResponseData: ExtractPostResponseData<T>
} {
	return "postResponseData" in response
}

/* Type Safe Object Iterators
-----------------------------------------------*/

export function entriesFromObject<T extends object, K extends keyof T>(object: T) {
	return Object.entries(object) as unknown as IterableIterator<[K, T[K]]>
}

export function keysFromObject<T extends object>(object: T): (keyof T)[] {
	return Object.keys(object) as (keyof T)[]
}
