//* #region ----------------------------------------------- Types

import type { GlobalActions } from "../init/events.js"

//* #endregion ----------------------------------------------- Types

//* #region ----------------------------------------------- Module Imports

import Request from "@helpers/request.js"
import App from "../init/app.js"

//* #endregion ----------------------------------------------- Module Imports

export default {
	execute: async (data: GlobalActions["search-value-inputted"]) => {
		const APP_COMPONENTS = App.getAppComponents()

		const response = await Request.getPartial("/partials/search", data)

		if (typeof response === "string") {
			APP_COMPONENTS.searchResults.show(response)
			return
		}

		//TODO: Handle error

		console.error(response.error)
	}
}
