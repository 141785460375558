//* #region ----------------------------------------------- Types

import type { GlobalActions } from "../init/events.js"

//* #endregion ----------------------------------------------- Types

//* #region ----------------------------------------------- Module Imports

import makeServerResponse from "@global/modals/ServerResultModal/render-server-response.js"
import Request from "@helpers/request.js"
import { makeFormRequest } from "front-end-helpers"
import { $ } from "haq"

//* #endregion ----------------------------------------------- Module Imports

export default {
	execute: (data: GlobalActions["subscribe-form-submitted"]) => {
		const { Form, SubmitBtn, ...payload } = data
		const formRequest = makeFormRequest({ Form, SubmitBtn })
		formRequest.init()

		grecaptcha.ready(() => {
			void grecaptcha
				.execute("6Lcev2UgAAAAAAyxaNdu3iyXx4RraQjiovgWyqOM", {
					action: "subscribe"
				})
				.then(async (token) => {
					const response = await Request.getPostResponse("/subscribe", {
						...payload,
						token
					})

					const SubscribeModal = $<MU_SubscribeModal["SubscribeModal"]>(document, "subscribe-modal")[0]
					SubscribeModal.hide()

					const serverReponse = makeServerResponse()
					if (response.success) {
						formRequest.afterResponse(true)
						serverReponse.show({
							resp: response,
							respTitle: response.title,
							respText: response.message
						})
						return
					}
					formRequest.afterResponse(false)
					serverReponse.show({
						resp: response,
						respTitle: "ERROR",
						respText: response.error
					})
				})
		})
	}
}
