//* #region ----------------------------------------------- Types

import type { GlobalActions } from "./events.js"

type ExecutedAction<T extends keyof GlobalActions> = Readonly<{
	execute: (data: GlobalActions[T]) => void
}>

type Router = {
	[key in keyof GlobalActions]: ExecutedAction<key>
}

//* #endregion ----------------------------------------------- Types

//* #region ----------------------------------------------- Module Imports (only events)

import contactFormSubmitted from "../events/contact-form-submitted.js"
import searchValueInputted from "../events/search-value-inputted.js"
import subscribeBtnClicked from "../events/subscribe-btn-clicked.js"
import subscribeFormSubmitted from "../events/subscribe-form-submitted.js"

//* #endregion ----------------------------------------------- Module Imports (only events)

function makeEventRouter() {
	const router: Router = {
		"search-value-inputted": searchValueInputted,
		"contact-form-submitted": contactFormSubmitted,
		"subscribe-btn-clicked": subscribeBtnClicked,
		"subscribe-form-submitted": subscribeFormSubmitted
	}

	return Object.freeze({
		get
	})

	function get<T extends keyof Router>(action: T) {
		return router[action]
	}
}

//* ---------- EXPORTS

const EVENT_ROUTER = makeEventRouter()

export default EVENT_ROUTER
