//* #region ----------------------------------------------- Types

import type { T_LangToggle } from "@WebComponents/LangToggle/LangToggle.js"

type T = MU_SearchResults & {
	langToggleEvents: T_LangToggle["events"]
}

export type T_AppBtn = T

import { getMiscTitles } from "@RenderLiterals/lang-titles.js"

//* #endregion ----------------------------------------------- Types

//* #region -----------------------------------------------  Module Imports (only utility/helper modules)

import RootDoc from "@helpers/rootDoc.js"
import { $, makeComponentEventListener } from "haq"

//* #endregion -----------------------------------------------  Module Imports (only utility/helper modules)

export default function makeSearchResults() {
	const SearchResults = $<T["SearchResults"]>(document, "search-results")[0]
	const CloseBtn = $<T["CloseBtn"]>(SearchResults, ".close-btn")[0]
	const Results = $<T["Results"]>(SearchResults, ".results")[0]
	const Heading = $<T["Heading"]>(SearchResults, "h3")[0]

	let currentLang: Lang = SearchResults.getAttribute("d_lang") || "en"

	let initialized = false

	return Object.freeze({
		init,
		show,
		hide
	})

	function init() {
		if (initialized || !currentLang) return

		const langToggleListeners = makeComponentEventListener<T["langToggleEvents"]>(RootDoc)
		langToggleListeners.add("lang-switched", (e) => _onLangSwitch(e.detail.lang))

		CloseBtn.addEventListener("click", () => hide())

		initialized = true
	}

	//* ---------- Exposed Methods -----------------------------------------------

	function show(html: string) {
		SearchResults.setAttribute("d_visible", true)
		Results.innerHTML = html
	}

	function hide() {
		SearchResults.removeAttribute("d_visible")
	}

	//* ---------- Listeners -----------------------------------------------

	function _onLangSwitch(lang: Lang) {
		currentLang = lang
		SearchResults.setAttribute("d_lang", lang)
		Heading.innerText = getMiscTitles("search-results-header")[currentLang]
	}
}
