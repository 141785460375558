//* #region ----------------------------------------------- Types

import type { MyComponentTypes } from "haq"

type T = MU_RemoveFilterBtn & {
	events: {
		"remove-filter-btn-clicked": {
			name: NoNullOrUndefined<Attributes["d_name"]>
			lang: Lang
		}
	}
}
type X = MyComponentTypes<T>
type C = T["RemoveFilterBtn"]
type E = T["events"]
type Attributes = T["RemoveFilterBtn"]["attr"]

export type T_RemoveFilterBtn = T

//* #endregion ----------------------------------------------- Types

//* #region -----------------------------------------------  Module Imports (only utility/helper modules)

import { $, TypedComponent, defineComponent, makeComponentEvent } from "haq"

//* #endregion -----------------------------------------------  Module Imports (only utility/helper modules)

export class RemoveFilterBtn extends HTMLElement {
	private Component: ReturnType<typeof TypedComponent<C>>
	private Span: X["Span"]
	private CloseBtn: X["CloseBtn"]

	private INITIALIZED = false

	constructor() {
		super()

		this.Component = TypedComponent<C>(this)
		this.Span = $<T["Span"]>(this, "span")[0]
		this.CloseBtn = $<T["CloseBtn"]>(this, ".close-btn")[0]
	}

	connectedCallback() {
		if (this.INITIALIZED) return

		this.CloseBtn.addEventListener("click", this._onCloseBtnClick.bind(this))
		this.INITIALIZED = true
	}

	setTextAndLang(text: string, lang: Lang, name: Attributes["d_name"]) {
		this.Span.innerText = text
		this.Component.setAttribute("d_lang", lang)
		this.Component.setAttribute("d_name", name)
	}

	private _onCloseBtnClick() {
		const name = this.Component.getAttribute("d_name")
		const lang = this.Component.getAttribute("d_lang")
		if (!name || !lang) return

		const closeEvent = makeComponentEvent<E>({
			triggerEl: this.Component,
			eventName: "remove-filter-btn-clicked",
			detail: { name, lang },
			bubbles: true
		})
		closeEvent.emit()
	}
}

export default function RemoveFilterBtnInit() {
	defineComponent<C>("remove-filter-btn", RemoveFilterBtn)
}
