//* #region ----------------------------------------------- Types

import type { T_LangToggle } from "@WebComponents/LangToggle/LangToggle.js"
import type { T_PlayTrackBtn } from "@WebComponents/PlayTrackBtn/PlayTrackBtn.js"

type T = MU_AudioPlayer & {
	playTrackBtnEvents: T_PlayTrackBtn["events"]
	langToggleEvents: T_LangToggle["events"]
}

export type TrackObj = {
	artworkURL: string
	src: string
	trackTitle: LangObj
	artist: LangObj
	album: OrNull<LangObj>
}

//* #endregion ----------------------------------------------- Types

//* #region ----------------------------------------------- Module Imports (only utility/helper modules)

import RootDoc from "@helpers/rootDoc.js"
import { $, createClone, getTypedComputedStyle, makeComponentEventListener } from "haq"

//* #endregion ----------------------------------------------- Module Imports (only utility/helper modules)

export default function makeAudioPlayer() {
	let INITIALIZED = false

	//ONLY PERSITED ELEMENTS AND CONSTANTS

	const AudioPlayer = $<T["AudioPlayer"]>(document, "audio-player")[0]

	const PlayerBtn = $<T["PlayerBtn"]>(AudioPlayer, ".player-btn")[0]
	const ArtworkImage = $<T["Img"]>(AudioPlayer, "img")[0]
	const PlayerMiddle = $<T["PlayerMiddle"]>(AudioPlayer, ".player-middle")[0]

	const TrackTitleTemplate = $<T["TrackTitleTemplate"]>(PlayerMiddle, "[x_selector='TrackTitleTemplate']")[0]
	const ArtistAlbumTitleTemplate = $<T["ArtistAlbumTitleTemplate"]>(
		PlayerMiddle,
		"[x_selector='ArtistAlbumTitleTemplate']"
	)[0]
	const MyMarquees = $<T["MyMarquee"]["MyMarquee"]>(PlayerMiddle, "my-marquee")
	const ProgressBar = $<T["ProgressBar"]["ProgressBar"]>(PlayerMiddle, "progress-bar")[0]

	const Progress = $<T["ProgressBar"]["Progress"]>(ProgressBar, ".progress")[0]
	const PlayHead = $<T["ProgressBar"]["PlayHead"]>(ProgressBar, ".play-head")[0]

	const PlayPauseBtn = $<T["ProgressBar"]["PlayPauseBtn"]>(PlayHead, ".play-pause-btn")[0]
	const PlayPauseBtnIcon = $<T["ProgressBar"]["MyIcon"]>(PlayHead, "[d_icon='play']")[0]
	const Loader = $<T["ProgressBar"]["Loader"]>(PlayHead, ".loader")[0]

	const MyVolume = $<T["MyVolume"]["MyVolume"]>(AudioPlayer, "my-volume")[0]
	const VolumeBtn = $<T["MyVolume"]["VolumeBtn"]>(MyVolume, ".volume-btn")[0]
	const VolumeWaves = $<T["MyVolume"]["VolumeIcon"]>(VolumeBtn, ".wave")
	const MuteBtn = $<T["MyVolume"]["MuteBtn"]>(MyVolume, ".mute-btn")[0]
	const VolumeBar = $<T["MyVolume"]["VolumeBar"]>(MyVolume, ".volume-bar")[0]

	const Duration = $<T["Duration"]>(AudioPlayer, "[x_selector='Duration']")[0]
	const DurationMobile = $<T["DurationMobile"]>(AudioPlayer, "[x_selector='DurationMobile']")[0]

	const PLAYLIST: TrackObj[] = []
	const AUDIO = new Audio()
	const PLAYBTN_WIDTH = 30

	let IS_INITIALIZED = false
	let IS_TOUCH_DEVICE: boolean
	let CURRENT_LANG = AudioPlayer.getAttribute("d_lang") || "en"
	let CURRENT_SONG_INDEX = 0
	let IS_PLAYING = false
	let IS_SCROLLING = false
	let IS_LOADING = false
	let GLOBAL_VOLUME = 1

	return Object.freeze({
		init
	})

	function init(isTouchDevice: boolean) {
		//EXECUTED ONCE IN APP'S LIFE CYCLE
		if (INITIALIZED) return

		IS_TOUCH_DEVICE = isTouchDevice

		window.addEventListener("resize", () => {
			for (const Marquee of MyMarquees) {
				Marquee.onResize()
			}
		})

		const langToggleEvents = makeComponentEventListener<T["langToggleEvents"]>(RootDoc)
		langToggleEvents.add("lang-switched", (e) => _onLangSwitch(e.detail.lang))

		PlayerBtn.addEventListener("click", _onPlayerBtnClick)
		const playTrackEvents = makeComponentEventListener<T["playTrackBtnEvents"]>(RootDoc)
		playTrackEvents.add("play-track-btn-clicked", (e) => _onPlayTrackBtnClick(e.detail.index))

		PlayPauseBtn.addEventListener("click", _onPlayPauseClick)

		AUDIO.addEventListener("loadstart", _onLoadStart)
		AUDIO.addEventListener("loadedmetadata", _onLoadEnd)
		AUDIO.addEventListener("timeupdate", _onTimeUpdate)
		AUDIO.addEventListener("loadeddata", _onTimeUpdate)
		AUDIO.addEventListener("ended", _playNext)
		document.addEventListener("keydown", _onSpaceBar)

		VolumeBtn.addEventListener("click", _toggleMuteUnmute)
		MuteBtn.addEventListener("click", _toggleMuteUnmute)

		if (IS_TOUCH_DEVICE) {
			MyVolume.classList.add("no-display")
			ProgressBar.addEventListener("touchstart", _onTouchStart)
		} else {
			PlayHead.addEventListener("mousedown", _onThumbDown)
			Progress.addEventListener("mousedown", _onThumbDown)
			VolumeBar.addEventListener("mousedown", _onVolumeDown)
		}

		INITIALIZED = true
	}

	function _show() {
		IS_INITIALIZED = true
		AudioPlayer.classList.remove("no-display")
		setTimeout(() => {
			AudioPlayer.removeAttribute("d_collapsed")
		}, 100)
	}

	function _updatePlaylist() {
		PLAYLIST.length = 0
		const PlayTrackBtns = $<MU_PlayTrackBtn["PlayTrackBtn"]>(document, "play-track-btn")
		for (const Btn of PlayTrackBtns) {
			PLAYLIST.push(Btn.trackObj)
		}
	}

	//* ---------- Lang Listener -----------------------------------------------

	function _onLangSwitch(lang: Lang) {
		CURRENT_LANG = lang
		AudioPlayer.setAttribute("d_lang", lang)
		for (const Marquee of MyMarquees) {
			Marquee.setAttribute("d_lang", lang)
		}
		if (IS_INITIALIZED) _updateTitles()
	}

	//* ---------- PlayerBtn (cassette) Listener -----------------------------------------------

	function _onPlayerBtnClick() {
		AudioPlayer.toggleAttribute("d_collapsed")
	}

	//* ---------- PlayTrackBtn Listener -----------------------------------------------

	async function _onPlayTrackBtnClick(index: number) {
		if (AudioPlayer.classList.contains("no-display")) _show() // first time using the audio player

		CURRENT_SONG_INDEX = index
		_updatePlaylist()
		_updateTeaser()
		_updateTitles()
		AUDIO.src = PLAYLIST[CURRENT_SONG_INDEX].src
		await _playAudio()
	}

	//* ---------- Play/pause Btn Listener -----------------------------------------------

	async function _onPlayPauseClick() {
		if (IS_SCROLLING) {
			//AVOID TOGGLING PLAY/PAUSE AFTER MOVING AND MOUSEUP TRIGGERS CLICK EVENT
			IS_SCROLLING = false
			return
		}
		if (AUDIO.paused) await _playAudio()
		else _pauseAudio()
	}

	//* ---------- Loading Listeners -----------------------------------------------

	function _onLoadStart() {
		IS_LOADING = true
		PlayPauseBtn.classList.add("no-display")
		Loader.classList.remove("no-display")
		ProgressBar.setAttribute("d_loading", true)
	}

	function _onLoadEnd() {
		IS_LOADING = false
		PlayPauseBtn.classList.remove("no-display")
		Loader.classList.add("no-display")
		ProgressBar.removeAttribute("d_loading")
	}

	//* ---------- Scrolling Listeners -----------------------------------------------

	function _onThumbDown(e: MouseEvent) {
		const offsetX = _getOffsetX(e.clientX)

		if (e.target === Progress) _updateTrackPosition(offsetX)

		document.addEventListener("mousemove", _onThumbMove)
		document.addEventListener("mouseup", _onThumbUp)
		AUDIO.removeEventListener("ended", _playNext)
	}

	function _onTouchStart(e: TouchEvent) {
		const offsetX = _getOffsetX(e.targetTouches[0].clientX)

		if (e.target === Progress) _updateTrackPosition(offsetX)

		ProgressBar.addEventListener("touchmove", _onTouchMove)
		ProgressBar.addEventListener("touchend", _onTouchEnd)
		AUDIO.removeEventListener("ended", _playNext)
	}

	function _onThumbMove(e: MouseEvent) {
		IS_SCROLLING = true
		const offsetX = _getOffsetX(e.clientX)
		_updateTrackPosition(offsetX)
	}

	function _onTouchMove(e: TouchEvent) {
		IS_SCROLLING = true
		const offsetX = _getOffsetX(e.targetTouches[0].clientX)
		_updateTrackPosition(offsetX)
	}

	async function _onThumbUp(e: MouseEvent) {
		const offsetX = _getOffsetX(e.clientX)
		AUDIO.addEventListener("ended", _playNext)
		_updateTrackPosition(offsetX)
		_getPercentComplete()
		document.removeEventListener("mousemove", _onThumbMove)
		document.removeEventListener("mouseup", _onThumbUp)
		if (e.target !== PlayPauseBtn) IS_SCROLLING = false
		if (IS_PLAYING && _getPercentComplete() === "100%") {
			await _playNext()
		}
	}

	async function _onTouchEnd(e: TouchEvent) {
		const offsetX = _getOffsetX(e.targetTouches[0].clientX)
		AUDIO.addEventListener("ended", _playNext)
		_updateTrackPosition(offsetX)
		_getPercentComplete()
		ProgressBar.removeEventListener("touchmove", _onTouchMove)
		ProgressBar.removeEventListener("touchend", _onTouchEnd)
		if (e.target !== PlayPauseBtn) IS_SCROLLING = false
		if (IS_PLAYING && _getPercentComplete() === "100%") {
			await _playNext()
		}
	}

	function _updateTrackPosition(offsetX: number) {
		const perc = Math.max(Math.min(offsetX / ProgressBar.offsetWidth, 1) * 100, 0)
		const duration = Number.isNaN(AUDIO.duration) ? 0 : AUDIO.duration
		AUDIO.currentTime = (perc * duration) / 100
		_onTimeUpdate()
	}

	function _getPercentComplete() {
		const ProgressBarStyle = getTypedComputedStyle<T["ProgressBar"]["ProgressBar"]>(ProgressBar)
		return ProgressBarStyle.getPropertyValue("--_percComplete")
	}

	function _getOffsetX(xPos: number) {
		return xPos - PlayerMiddle.getBoundingClientRect().x - PLAYBTN_WIDTH / 2
	}

	//* ---------- Volume Listeners -----------------------------------------------

	function _onVolumeDown(e: MouseEvent) {
		const offsetX = e.offsetX
		_updateVolume(offsetX)
		document.addEventListener("mousemove", _onVolumeMove)
		document.addEventListener("mouseup", _onVolumeUp)
	}
	function _onVolumeMove(e: MouseEvent) {
		const offsetX = e.clientX - VolumeBar.getBoundingClientRect().x
		_updateVolume(offsetX)
	}
	function _onVolumeUp() {
		document.removeEventListener("mousemove", _onVolumeMove)
		document.removeEventListener("mouseup", _onVolumeUp)
	}

	function _updateVolume(offsetX: number) {
		const value = Math.min(Math.max(offsetX / (VolumeBar.offsetWidth || 1), 0), 1)
		if (value > 0) {
			AUDIO.muted = false
		} else {
			AUDIO.muted = true
		}
		AUDIO.volume = value
		GLOBAL_VOLUME = value
		MyVolume.style.setProperty("--_perc", `${value * 100}%`)
		MuteBtn.classList.add("no-display")
		VolumeBtn.classList.remove("no-display")
		for (const Wave of VolumeWaves) {
			Wave.setAttribute("d_selected", true)
		}
		if (value <= 0.66 && value > 0.33) {
			VolumeWaves[VolumeWaves.length - 1].removeAttribute("d_selected")
		} else if (value <= 0.33 && value > 0) {
			VolumeWaves[VolumeWaves.length - 1].removeAttribute("d_selected")
			VolumeWaves[VolumeWaves.length - 2].removeAttribute("d_selected")
		} else if (value === 0) {
			for (const Wave of VolumeWaves) {
				Wave.removeAttribute("d_selected")
			}
			VolumeBtn.classList.add("no-display")
			MuteBtn.classList.remove("no-display")
		}
	}

	//* ---------- Audio Controls -----------------------------------------------

	async function _playAudio() {
		try {
			await AUDIO.play()
			PlayPauseBtn.setAttribute("title", "Pause")
			PlayPauseBtnIcon.setAttribute("d_icon", "pause")
			AudioPlayer.setAttribute("d_playing", true)
			IS_PLAYING = true
		} catch {
			IS_PLAYING = false
			PlayPauseBtn.setAttribute("title", "Play")
			PlayPauseBtnIcon.setAttribute("d_icon", "play")
		}
	}

	function _pauseAudio() {
		AUDIO.pause()
		PlayPauseBtn.setAttribute("title", "Play")
		PlayPauseBtnIcon.setAttribute("d_icon", "play")
		AudioPlayer.removeAttribute("d_playing")
		IS_PLAYING = false
	}

	async function _playNext() {
		if (CURRENT_SONG_INDEX === PLAYLIST.length - 1) {
			//end of playlist
			_pauseAudio()
			AUDIO.currentTime = 0
		} else {
			AUDIO.pause()
			CURRENT_SONG_INDEX++
			AUDIO.src = PLAYLIST[CURRENT_SONG_INDEX].src
			_updateTitles()
			if (IS_PLAYING) await _playAudio()
		}
	}

	async function _onSpaceBar(e: KeyboardEvent) {
		if (!(e.target instanceof HTMLElement)) return
		if (e.target.nodeName !== "INPUT" && e.target.nodeName !== "TEXTAREA") {
			if (e.code === "Space") {
				e.preventDefault() //avoid automatic scrolling page down
				if (!IS_LOADING) {
					IS_SCROLLING = false
					await _onPlayPauseClick()
				}
			}
		}
	}

	//* ---------- Volume Controls -----------------------------------------------

	function _toggleMuteUnmute() {
		VolumeBtn.classList.toggle("no-display")
		MuteBtn.classList.toggle("no-display")
		let percentValue: string
		if (AUDIO.muted) {
			AUDIO.muted = false
			AUDIO.volume = GLOBAL_VOLUME
			percentValue = `${GLOBAL_VOLUME * 100}%`
		} else {
			AUDIO.muted = true
			percentValue = "0%"
		}
		MyVolume.style.setProperty("--_perc", percentValue)
	}

	//* ---------- DOM Updates -----------------------------------------------

	function _updateTeaser() {
		ArtworkImage.src = PLAYLIST[CURRENT_SONG_INDEX].artworkURL
		ArtworkImage.alt = `Artwork for album: ${PLAYLIST[CURRENT_SONG_INDEX].album[CURRENT_LANG]}`
	}

	function _updateTitles() {
		// Track title
		const TrackClone = createClone(TrackTitleTemplate.content, true)
		const TrackTitle = $<T["TrackTitle"]>(TrackClone, "p")[0]
		TrackTitle.innerText = PLAYLIST[CURRENT_SONG_INDEX].trackTitle[CURRENT_LANG]
		MyMarquees[0].text = TrackClone

		//Arist & Album titles
		const ArtistAlbumClone = createClone(ArtistAlbumTitleTemplate.content, true)
		const ArtistAlbumTitle = $<T["ArtisAlbumtTitle"]>(ArtistAlbumClone, "[x_selector='ArtisAlbumtTitle']")[0]
		ArtistAlbumTitle.innerHTML = PLAYLIST[CURRENT_SONG_INDEX].album[CURRENT_LANG]
			? `<strong>${PLAYLIST[CURRENT_SONG_INDEX].artist[CURRENT_LANG]}</strong> | ${PLAYLIST[CURRENT_SONG_INDEX].album[CURRENT_LANG]}`
			: `<strong>${PLAYLIST[CURRENT_SONG_INDEX].artist[CURRENT_LANG]}</strong>`
		MyMarquees[1].text = ArtistAlbumClone
	}

	function _onTimeUpdate() {
		const percentComplete = (AUDIO.currentTime / AUDIO.duration) * 100
		ProgressBar.style.setProperty("--_percComplete", `${percentComplete}%`)
		const duration = Number.isNaN(AUDIO.duration) ? "0:00" : _formatAudioTime(AUDIO.duration)
		Duration.innerHTML = `<strong>${_formatAudioTime(AUDIO.currentTime)}</strong> | ${duration}`
		DurationMobile.innerHTML = Number.isNaN(AUDIO.duration)
			? "0:00"
			: `-${_formatAudioTime(AUDIO.duration - AUDIO.currentTime)}`
	}

	function _formatAudioTime(duration: number) {
		const hours = Math.floor(duration / 3600)
		const totalHours: string = hours >= 1 ? `${hours}:` : ""
		let totalMin: string | number = Math.floor(duration / 60 - hours * 60)
		let totalSec: string | number = Math.floor(duration - totalMin * 60 - hours * 3600)
		totalMin = totalMin < 10 && hours >= 1 ? `0${totalMin}` : totalMin
		totalSec = totalSec < 10 ? `0${totalSec}` : totalSec
		return `${totalHours}${totalMin}:${totalSec}`
	}
}
