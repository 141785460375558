//* #region ----------------------------------------------- Types

import type { MyComponentTypes } from "haq"

type T = MU_SubscribeModal & {
	events: {
		"subscribe-form-submitted": T["FormData"] & {
			lang: Lang
			Form: X["Form"]
			SubmitBtn: X["SubmitBtn"]
		}
	}
}

type X = MyComponentTypes<T>
type C = T["SubscribeModal"]
type E = T["events"]

export type T_SubscribeModal = T

//* #endregion ----------------------------------------------- Types

//* #region -----------------------------------------------  Module Imports (only utility/helper modules)

import { $, TypedFormData, _, defineComponent, makeAppEvents } from "haq"

//* #endregion -----------------------------------------------  Module Imports (only utility/helper modules)

export class SubscribeModal extends HTMLElement {
	private Overlay: X["SubscribeOverlay"]
	private Form: X["Form"]
	private SubmitBtn: X["SubmitBtn"]

	private CURRENT_LANG: Lang | null = null
	private INITIALIZED = false

	constructor() {
		super()

		this.Overlay = _<T["SubscribeOverlay"]>("subscribeOverlay")
		this.Form = $<T["Form"]>(this, "form")[0]
		this.SubmitBtn = $<T["SubmitBtn"]>(this.Form, "[x_selector='submitBtn']")[0]
	}

	connectedCallback() {
		if (this.INITIALIZED) return

		this.CURRENT_LANG = this.Overlay.getAttribute("d_lang") || "en"
		this.Form.addEventListener("submit", this._onFormSubmit.bind(this))

		this.INITIALIZED = true
	}

	show() {
		this.Overlay.show()
	}

	hide() {
		this.Overlay.hide()
	}

	private _onFormSubmit(e: SubmitEvent) {
		e.preventDefault()

		if (!this.CURRENT_LANG) return

		const FormData = TypedFormData<T["FormData"]>(this.Form)

		const payload = {
			fullName: FormData.get("fullName") || "",
			email: FormData.get("email") || ""
		}

		const globalEvents = makeAppEvents<E>()

		globalEvents.emit({
			action: "subscribe-form-submitted",
			data: {
				...payload,
				lang: this.CURRENT_LANG,
				Form: this.Form,
				SubmitBtn: this.SubmitBtn
			}
		})
	}
}

export default function SubscribeModalInit() {
	defineComponent<C>("subscribe-modal", SubscribeModal)
}
