//* #region ----------------------------------------------- Types

import type { MyComponentTypes } from "haq"

type T = MU_FilterResults
type X = MyComponentTypes<T>
type C = T["FilterResults"]

export type T_FilterResults = T

//* #endregion ----------------------------------------------- Types

//* #region -----------------------------------------------  Module Imports (only utility/helper modules)

import { getMiscTitles } from "@RenderLiterals/lang-titles"
import { $, TypedComponent, createClone, defineComponent } from "haq"

//* #endregion -----------------------------------------------  Module Imports (only utility/helper modules)

export class FilterResults extends HTMLElement {
	private Component: ReturnType<typeof TypedComponent<C>>
	private RemoveFilterBtnsWrapper: X["RemoveFilterBtnsWrapper"]
	private RemoveFilterBtnTemplate: X["RemoveFilterBtnTemplate"]
	private NoResultsText: X["NoResultsText"]

	private INITIALIZED = false

	constructor() {
		super()

		this.Component = TypedComponent<C>(this)
		this.RemoveFilterBtnsWrapper = $<T["RemoveFilterBtnsWrapper"]>(this, "[x_selector='RemoveFilterBtnsWrapper']")[0]
		this.RemoveFilterBtnTemplate = $<T["RemoveFilterBtnTemplate"]>(this, "[x_selector='RemoveFilterBtnTemplate']")[0]
		this.NoResultsText = $<T["NoResultsText"]>(this, "[x_selector='NoResultsText']")[0]
	}

	connectedCallback() {
		if (this.INITIALIZED) return

		this.INITIALIZED = true
	}

	show() {
		this.Component.setAttribute("d_visible", true)
	}

	hide() {
		this.Component.removeAttribute("d_visible")
	}

	reset() {
		this.RemoveFilterBtnsWrapper.replaceChildren()
	}

	update(values: string[], lang: Lang, name: keyof TapeFilters) {
		const text = lang === "ar" ? values[1] : values[0]
		const clone = createClone(this.RemoveFilterBtnTemplate.content, true)
		const RemoveFilterBtn = $<T["RemoveFilterBtn"]>(clone, "remove-filter-btn")[0]
		this.RemoveFilterBtnsWrapper.append(RemoveFilterBtn)
		RemoveFilterBtn.setTextAndLang(text, lang, name)
	}

	showNoResults(lang: Lang) {
		this.NoResultsText.innerText = getMiscTitles("no-results")[lang]
		this.NoResultsText.setAttribute("d_lang", lang)
		this.NoResultsText.classList.remove("no-display")
	}

	hideNoResults() {
		this.NoResultsText.classList.add("no-display")
	}
}

export default function FilterResultsInit() {
	defineComponent<C>("filter-results", FilterResults)
}
