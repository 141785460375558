//* #region ----------------------------------------------- Types

import type { T_LangToggle } from "@WebComponents/LangToggle/LangToggle.js"
import type APP_EVENTS from "src/client/js/app/init/events.js"

type T = MU_SearchForm & {
	langToggleEvents: T_LangToggle["events"]
	events: {
		"search-value-inputted": {
			query: string
			lang: Lang
		}
	}
}

export type T_SearchForm = T

type Dependencies = { events: typeof APP_EVENTS }

//* #endregion ----------------------------------------------- Types

//* #region ----------------------------------------------- Module Imports (only utility/helper modules)

import RootDoc from "@helpers/rootDoc.js"
import { $, _, makeComponentEventListener } from "haq"

//* #endregion ----------------------------------------------- Module Imports (only utility/helper modules)

export default function makeSearchForm({ events }: Dependencies) {
	const SearchForm = _<T["SearchForm"]>("searchForm")
	const Inputs = $<T["TextInput"]>(SearchForm, ".input-field")
	const SearchBtn = $<T["SearchBtn"]>(SearchForm, "[x_selector='SearchBtn']")[0]

	const _onKeyup = _debounce((query: string, lang: Lang) => {
		events.emit({
			action: "search-value-inputted",
			data: { lang, query }
		})
	}, 500)

	let CURRENT_LANG: Lang = SearchForm.getAttribute("d_lang") || "en"
	let IS_TOUCH_DEVICE: boolean
	let initialized = false

	return Object.freeze({
		init
	})

	function init(isTouchDevice: boolean) {
		if (initialized || !CURRENT_LANG) return

		IS_TOUCH_DEVICE = isTouchDevice

		const langToggleListeners = makeComponentEventListener<T["langToggleEvents"]>(RootDoc)
		langToggleListeners.add("lang-switched", (e) => _onLangSwitch(e.detail.lang))

		if (IS_TOUCH_DEVICE) {
			SearchBtn.classList.remove("no-display")
			SearchBtn.addEventListener("click", () => {
				const TargetInput = Inputs.find((Input) => Input.getAttribute("d_lang") === CURRENT_LANG)
				if (!TargetInput) return
				_onKeyup(TargetInput.value.trim(), CURRENT_LANG)
			})
		} else {
			for (const Input of Inputs) {
				Input.addEventListener("input", () => {
					if (Input.value.trim().length < 3) return
					_onKeyup(Input.value.trim(), CURRENT_LANG)
				})
			}
		}

		initialized = true
	}

	function _onLangSwitch(lang: Lang) {
		CURRENT_LANG = lang
		const enInput = Inputs.find((Input) => Input.getAttribute("d_lang") === "en")
		const arInput = Inputs.find((Input) => Input.getAttribute("d_lang") === "ar")
		if (!enInput || !arInput) return
		for (const Input of Inputs) {
			Input.classList.add("no-display")
		}
		if (lang === "ar") arInput.classList.remove("no-display")
		else enInput.classList.remove("no-display")
	}

	function _debounce(cb: (query: string, lang: Lang) => void, delay: number) {
		let timeout: NodeJS.Timeout
		return (query: string, lang: Lang) => {
			clearTimeout(timeout)
			timeout = setTimeout(() => {
				cb(query, lang)
			}, delay)
		}
	}
}
