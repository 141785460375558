//* #region ----------------------------------------------- Types

//* #endregion ----------------------------------------------- Types

//* #region ----------------------------------------------- Module Imports

import { $ } from "haq"

//* #endregion ----------------------------------------------- Module Imports

export default {
	execute: () => {
		const SubscribeModal = $<MU_SubscribeModal["SubscribeModal"]>(document, "subscribe-modal")[0]
		SubscribeModal.show()
	}
}
