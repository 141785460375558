//* #region ----------------------------------------------- Types

import type { MyComponentTypes } from "haq"

type T = MU_LangToggle & {
	events: {
		"lang-switched": {
			lang: Lang
		}
	}
}
type X = MyComponentTypes<T>
type C = T["LangToggle"]
type E = T["events"]

export type T_LangToggle = T

//* #endregion ----------------------------------------------- Types

//* #region -----------------------------------------------  Module Imports (only utility/helper modules)

import { $, TypedComponent, _, defineComponent, makeComponentEvent } from "haq"

//* #endregion -----------------------------------------------  Module Imports (only utility/helper modules)

export class LangToggle extends HTMLElement {
	private Component: ReturnType<typeof TypedComponent<C>>
	private Input: X["LangCheckBoxInput"]
	private AnchorLink: X["AnchorLink"]

	private CURRENT_LANG: Lang = "en"

	private INITIALIZED = false //handle Astro persisted components using View transitions

	constructor() {
		super()

		this.Component = TypedComponent<C>(this)
		this.Input = _<T["LangCheckBoxInput"]>("langCheckBoxInput")
		this.AnchorLink = $<T["AnchorLink"]>(this, "a")[0]
	}

	//* ---------- Initialize -----------------------------------------------

	connectedCallback() {
		if (!this.INITIALIZED) {
			this.AnchorLink.addEventListener("click", () => {
				this.Input.checked = !this.Input.checked
				this.Input.dispatchEvent(new Event("change"))
			})

			this.Input.addEventListener("change", () => {
				this.CURRENT_LANG = this.Input.checked ? "ar" : "en"
				this.Component.setAttribute("d_currentLang", this.CURRENT_LANG)

				const changeEvent = makeComponentEvent<E>({
					triggerEl: this,
					eventName: "lang-switched",
					detail: { lang: this.CURRENT_LANG },
					bubbles: true
				})
				changeEvent.emit()
			})

			this.CURRENT_LANG = this.Component.getAttribute("d_currentLang") || "en"
		}

		this.INITIALIZED = true
	}

	//* ---------- Exposed Methods -----------------------------------------------

	get currentLang() {
		return this.CURRENT_LANG
	}

	triggerLangChangeEvent(langChanged?: boolean) {
		if (langChanged) {
			this.Input.checked = !this.Input.checked
			this.Input.dispatchEvent(new Event("change"))
		}
	}

	updateLangURL() {
		const queryString = window.location.search
		const path = window.location.pathname
		const urlParams = new URLSearchParams(queryString)
		const urlLang = !urlParams.get("lang") ? "en" : urlParams.get("lang")
		this.triggerLangChangeEvent(this.CURRENT_LANG !== urlLang)

		const targetLang = this.CURRENT_LANG === "ar" ? "en" : "ar"
		urlParams.set("lang", targetLang)
		this.AnchorLink.href = `${path}?${urlParams.toString()}`
	}
}

export default function LangToggleInit() {
	defineComponent<C>("lang-toggle", LangToggle)
}
