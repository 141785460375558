//* #region ----------------------------------------------- Types

import type { AppEvent } from "haq"
import type { GlobalActions } from "./init/events.js"

//* #endregion ----------------------------------------------- Types

//* #region ----------------------------------------------- Module Imports (only App)

import App from "./init/app.js"

//* #endregion ----------------------------------------------- Module Imports (only App)

//* ---------- Entry Point IIFE -----------------------------------------------
;(() => {
	window.addEventListener("resize", App.resize)
	App.resize()

	window.addEventListener("view-transition-end", () => {
		App.onAfterSwap()
	})

	window.addEventListener("app-event", (e) => {
		App.executeEvent((e as AppEvent<GlobalActions>).detail)
	})

	document.documentElement.addEventListener("touchstart", () => {}) //hover behaviour on touch devices
})()
