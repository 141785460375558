//* #region ----------------------------------------------- Types

import type { AppEventData } from "haq"
import type { GlobalActions } from "./events.js"

//* #endregion ----------------------------------------------- Types

//* #region ----------------------------------------------- Module Imports (only utility/helper modules)

import AppBtnInit from "@WebComponents/AppBtn/AppBtn.js"
import ContactFormInit from "@WebComponents/ContactForm/ContactForm.js"
import FilterResultsInit from "@WebComponents/FilterResults/FilterResults.js"
import ImagePreviewModalInit from "@WebComponents/ImagePreviewModal/ImagePreviewModal.js"
import LangToggleInit from "@WebComponents/LangToggle/LangToggle.js"
import LazyImageInit from "@WebComponents/LazyImage/LazyImage.js"
import MultiLangInputInit from "@WebComponents/MultiLangInput/MultiLangInput.js"
import MyCarouselInit from "@WebComponents/MyCarousel/MyCarousel.js"
import MyMarqueeInit from "@WebComponents/MyMarquee/MyMarquee.js"
import MyOverlayInit from "@WebComponents/MyOverlay/MyOverlay.js"
import MyTagInit from "@WebComponents/MyTag/MyTag.js"
import PlayTrackBtnInit from "@WebComponents/PlayTrackBtn/PlayTrackBtn.js"
import RemoveFilterBtnInit from "@WebComponents/RemoveFilterBtn/RemoveFilterBtn.js"
import SideBarInit from "@WebComponents/SideBar/SideBar.js"
import SubscribeModalInit from "@WebComponents/SubscribeModal/SubscribeModal.js"
import TapeGridInit from "@WebComponents/TapeGrid/TapeGrid.js"
import RootDoc from "@helpers/rootDoc.js"
import makeAppComponents from "../components/index.js"
import EVENT_ROUTER from "./event-router.js"

//* #endregion ----------------------------------------------- Module Imports (only utility/helper modules)

function makeApp() {
	let APP_COMPONENTS: ReturnType<typeof makeAppComponents>

	_defineWebComponents()
	_init()

	return Object.freeze({
		resize,
		onAfterSwap,
		executeEvent,
		getAppComponents,
		isTouchDevice
	})

	function _defineWebComponents() {
		MyOverlayInit()
		ImagePreviewModalInit()
		MyCarouselInit()
		FilterResultsInit()
		RemoveFilterBtnInit()
		LangToggleInit()
		SideBarInit()
		TapeGridInit()
		LazyImageInit()
		AppBtnInit()
		MultiLangInputInit()
		PlayTrackBtnInit()
		MyMarqueeInit()
		MyTagInit()
		ContactFormInit()
		SubscribeModalInit()
	}

	function _init() {
		APP_COMPONENTS = makeAppComponents()
		APP_COMPONENTS.appResizer.init()
		APP_COMPONENTS.filterForm.init()
		APP_COMPONENTS.searchForm.init(isTouchDevice())
		APP_COMPONENTS.searchResults.init()
		APP_COMPONENTS.audioPlayer.init(isTouchDevice())
	}

	function onAfterSwap() {
		APP_COMPONENTS.appResizer.afterViewTransition()
		APP_COMPONENTS.filterForm.update()
		APP_COMPONENTS.searchResults.hide()
	}

	function resize() {
		const vh = window.innerHeight / 100
		RootDoc.style.setProperty("--_vh", `${vh}px`)
	}

	function executeEvent<T extends GlobalActions, K extends keyof GlobalActions>(detail: AppEventData<T, K>) {
		const event = EVENT_ROUTER.get(detail.action)
		event.execute(detail.data)
	}

	function getAppComponents() {
		return APP_COMPONENTS
	}

	function isTouchDevice() {
		return "ontouchstart" in document.documentElement
	}
}

const App = makeApp()
export default App
