//* #region ----------------------------------------------- Types

import type { TrackObj } from "@WebComponents/AudioPlayer/audio-player.js"

type T = MU_PlayTrackBtn & {
	events: {
		"play-track-btn-clicked": {
			index: number
		}
	}
}
type C = T["PlayTrackBtn"]
type E = T["events"]

export type T_PlayTrackBtn = T

//* #endregion ----------------------------------------------- Types

//* #region -----------------------------------------------  Module Imports (only utility/helper modules)

import { TypedComponent, defineComponent, makeComponentEvent } from "haq"

//* #endregion -----------------------------------------------  Module Imports (only utility/helper modules)

export class PlayTrackBtn extends HTMLElement {
	private Component: ReturnType<typeof TypedComponent<C>>

	private TRACK_OBJ = <TrackObj>{}

	constructor() {
		super()

		this.Component = TypedComponent<C>(this)
	}

	connectedCallback() {
		const index = this.Component.getAttribute("d_index")
		const artworkURL = this.Component.getAttribute("d_artworkURL")
		const src = this.Component.getAttribute("d_src")
		const trackTitleEN = this.Component.getAttribute("d_trackEN")
		const trackTitleAR = this.Component.getAttribute("d_trackAR")
		const artistEN = this.Component.getAttribute("d_artistEN")
		const artistAR = this.Component.getAttribute("d_artistAR")
		const albumEN = this.Component.getAttribute("d_albumEN")
		const albumAR = this.Component.getAttribute("d_albumAR")

		if (
			!index ||
			Number.isNaN(Number(index)) ||
			!artworkURL ||
			!src ||
			!trackTitleEN ||
			!trackTitleAR ||
			!artistEN ||
			!artistAR
		)
			return

		this.TRACK_OBJ = {
			artworkURL,
			src,
			trackTitle: {
				en: trackTitleEN,
				ar: trackTitleAR
			},
			artist: {
				en: artistEN,
				ar: artistAR
			},
			album: {
				en: albumEN,
				ar: albumAR
			}
		}

		this.Component.addEventListener("click", () => {
			const event = makeComponentEvent<E>({
				triggerEl: this.Component,
				eventName: "play-track-btn-clicked",
				detail: {
					index: Number(index)
				},
				bubbles: true
			})

			event.emit()
		})
	}

	get trackObj() {
		return this.TRACK_OBJ
	}
}

export default function PlayTrackBtnInit() {
	defineComponent<C>("play-track-btn", PlayTrackBtn)
}
