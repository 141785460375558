//* #region ----------------------------------------------- Types

import type { MyComponentTypes } from "haq"

//Component Dependencies (injected slots), possibly null
type D = {
	input: MU_InputField["InputField"]
	select: MU_Select["Select"]
	multiLangElement: MU_Footer["MultiLangElement"]
}

type T = MU_MultiLangInput & {
	events: {
		"select-input-changed": undefined
	}
}
type Y = MyComponentTypes<D>
type C = T["MultiLangInput"]
type Attributes = T["MultiLangInput"]["attr"]
type E = T["events"]

export type T_MultiLangInput = T

//* #endregion ----------------------------------------------- Types

//* #region -----------------------------------------------  Module Imports (only utility/helper modules)

import { $, TypedComponent, defineComponent, makeComponentEvent } from "haq"

//* #endregion -----------------------------------------------  Module Imports (only utility/helper modules)

export class MultiLangInput extends HTMLElement {
	private Component: ReturnType<typeof TypedComponent<C>>
	private Inputs: Y["input"][]
	private EnInput: Y["multiLangElement"] | undefined
	private ArInput: Y["multiLangElement"] | undefined
	private SelectInputs: Y["select"][]

	private InputName: Attributes["d_name"]

	private selectChangedEvent = makeComponentEvent<E>({
		triggerEl: this,
		eventName: "select-input-changed",
		bubbles: true
	})

	private INITIALIZED = false //handle Astro persisted components using View transitions

	constructor() {
		super()

		this.Component = TypedComponent<C>(this)
		this.Inputs = $<D["input"]>(this, ".input-field")
		this.SelectInputs = $<D["select"]>(this, "select")

		this.EnInput = $<D["multiLangElement"]>(this, "[d_lang='en']")[0]
		this.ArInput = $<D["multiLangElement"]>(this, "[d_lang='ar']")[0]

		this.InputName = this.Component.getAttribute("d_name") || "genre"
	}

	//* ---------- Initialize -----------------------------------------------

	connectedCallback() {
		if (this.INITIALIZED || !this.InputName) return

		for (const Input of this.SelectInputs) {
			Input.addEventListener("change", () => {
				for (const Select of this.SelectInputs) {
					Select.selectedIndex = Input.selectedIndex
				}

				this.selectChangedEvent.emit()
			})
		}

		this.INITIALIZED = true
	}

	//* ---------- Exposed Methods -----------------------------------------------

	get inputName() {
		return this.InputName
	}

	resetValues() {
		for (const Input of this.Inputs) {
			Input.value = ""
			Input.removeAttribute("value")
		}
	}

	setValue(lang: Lang, value: string) {
		if (lang === "ar") this.Inputs[1].value = value
		else this.Inputs[0].value = value
	}

	setValues(valueArray: string[]) {
		for (const [index, Input] of this.Inputs.entries()) {
			Input.value = valueArray[index]
		}
		//this.Inputs.forEach((Input, index) => (Input.value = value[index]));
	}

	setLang(lang: Lang) {
		this.EnInput?.classList.add("no-display")
		this.ArInput?.classList.add("no-display")
		if (lang === "ar") this.ArInput?.classList.remove("no-display")
		else this.EnInput?.classList.remove("no-display")
	}

	triggerInputChange(lang: Lang) {
		const ev = new Event("change")
		this.Inputs[lang === "ar" ? 1 : 0].dispatchEvent(ev)
	}
}

export default function MultiLangInputInit() {
	defineComponent<C>("multi-lang-input", MultiLangInput)
}
