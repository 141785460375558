//* #region ----------------------------------------------- Types

import type { MyComponentTypes } from "haq"

type T = MU_LazyImage & {
	events: {
		"previewable-clicked": {
			index: number
		}
	}
}
type X = MyComponentTypes<T>
type C = T["LazyImage"]
type E = T["events"]

export type T_LazyImage = T

//* #endregion ----------------------------------------------- Types

//* #region ----------------------------------------------- Module Imports (only utility/helper modules)

import { $, TypedComponent, defineComponent, makeComponentEvent } from "haq"

//* #endregion ----------------------------------------------- Module Imports (only utility/helper modules)

export class LazyImage extends HTMLElement {
	private Component: ReturnType<typeof TypedComponent<C>>
	private Img: X["Img"]

	private INITIALIZED = false

	constructor() {
		super()

		this.Component = TypedComponent<C>(this)
		this.Img = $<T["Img"]>(this, "img")[0]
	}

	//* ---------- Initialize -----------------------------------------------

	connectedCallback() {
		if (this.INITIALIZED) return
		this.INITIALIZED = true
		if (this.Component.getAttribute("d_previewable") !== null)
			this.Component.addEventListener("click", this._onPreviewableClick.bind(this))
		if (this.Img.complete) {
			this._onLoaded()
			return
		}
		this.Img.addEventListener("load", this._onLoaded.bind(this))
	}

	//* ---------- Listeners -----------------------------------------------

	private _onLoaded() {
		this.Component.setAttribute("d_loaded", true)
	}

	private _onPreviewableClick() {
		const index = this.Component.getAttribute("d_index")
		if (!index || Number.isNaN(index)) return

		const event = makeComponentEvent<E>({
			triggerEl: this,
			eventName: "previewable-clicked",
			detail: {
				index: Number(index)
			},
			bubbles: true
		})

		event.emit()
	}
}

export default function LazyImageInit() {
	defineComponent<C>("lazy-image", LazyImage)
}
