import type { T_ContactForm } from "@WebComponents/ContactForm/ContactForm.js"
import type { T_SearchForm } from "@WebComponents/SideBar/SearchForm/search-form.js"
import type { T_SideBar } from "@WebComponents/SideBar/SideBar.js"
import type { T_SubscribeModal } from "@WebComponents/SubscribeModal/SubscribeModal.js"
import { makeAppEvents } from "haq"

export type GlobalActions = T_SearchForm["events"] &
	T_ContactForm["events"] &
	T_SideBar["globalEvents"] &
	T_SubscribeModal["events"]

const APP_EVENTS = makeAppEvents<GlobalActions>()
export default APP_EVENTS
