//* #region ----------------------------------------------- Types

import type { MyComponentTypes } from "haq"

type T = MU_MyMarquee
type X = MyComponentTypes<T>
type C = T["MyMarquee"]

export type T_MyMarquee = T

//* #endregion ----------------------------------------------- Types

//* #region -----------------------------------------------  Module Imports (only utility/helper modules)

import { $, TypedComponent, createClone, defineComponent, getChildren, getTypedComputedStyle } from "haq"

//* #endregion -----------------------------------------------  Module Imports (only utility/helper modules)

export class MyMarquee extends HTMLElement {
	private Component: ReturnType<typeof TypedComponent<C>>
	private Inner: X["Inner"]

	constructor() {
		super()

		this.Component = TypedComponent<C>(this)
		this.Inner = $<T["Inner"]>(this, ".inner")[0]
	}

	onResize() {
		const children = getChildren<T["Clonable"]>(this.Inner)
		if (!children.length) return

		const INNER_WIDTH = children[0].offsetWidth
		const offsetWidth = this.Component.offsetWidth
		const ComponentStyle = getTypedComputedStyle<T["MyMarquee"]>(this.Component)
		const PADDING = Number.parseFloat(ComponentStyle.getPropertyValue("--_paddingInline"))
		const GRADIENT_END_PERCENT = Number.parseFloat(ComponentStyle.getPropertyValue("--_gradientEnd"))
		if (Number.isNaN(PADDING) || Number.isNaN(GRADIENT_END_PERCENT)) return

		const computedWidth = (offsetWidth - PADDING * 2) * (GRADIENT_END_PERCENT / 100)
		if (INNER_WIDTH >= computedWidth) this._startMarqueeAnimation()
		else this._stopMarqueeAnimation()
	}

	private _startMarqueeAnimation() {
		this.Component.setAttribute("d_active", true)
		const children = getChildren<T["Clonable"]>(this.Inner)
		if (!children.length || children.length > 1) return

		const Clone = createClone<T["Clonable"]>(children[0], true)
		this.Inner.appendChild(Clone)
	}

	private _stopMarqueeAnimation() {
		this.Component.removeAttribute("d_active")
		const children = getChildren<T["Clonable"]>(this.Inner)
		if (!children.length || children.length === 1) return

		children[1].remove()
	}

	set text(content: DocumentFragment) {
		this.Inner.replaceChildren()
		this.Inner.append(content)
		this.Component.removeAttribute("d_active")
		setTimeout(() => {
			this.onResize()
		}, 3000)
	}
}

export default function MyMarqueeInit() {
	defineComponent<C>("my-marquee", MyMarquee)
}
