//* #region ----------------------------------------------- Types

import type { GlobalActions } from "../init/events.js"

//* #endregion ----------------------------------------------- Types

//* #region ----------------------------------------------- Module Imports

import makeServerResponse from "@global/modals/ServerResultModal/render-server-response.js"
import Request from "@helpers/request.js"
import { makeFormRequest } from "front-end-helpers"

//* #endregion ----------------------------------------------- Module Imports

export default {
	execute: async (data: GlobalActions["contact-form-submitted"]) => {
		const { Form, SubmitBtn, ...payload } = data
		const formRequest = makeFormRequest({ Form, SubmitBtn })
		formRequest.init()

		const response = await Request.getPostResponse("/contact", { ...payload })

		const serverReponse = makeServerResponse()
		if (response.success) {
			formRequest.afterResponse(true)
			serverReponse.show({
				resp: response,
				respTitle: response.title,
				respText: response.message
			})
			return
		}
		formRequest.afterResponse(false)
		serverReponse.show({
			resp: response,
			respTitle: "ERROR",
			respText: response.error
		})
	}
}
